<template>
  <div id="dynamicDepositBonus" class="depositBonus">
    <!--Banner Part-->
    <div :class="{ 'banner-container': true, banner: !isMena, 'banner-mena': isMena || isOthers }">
      <div class="banner-wording">
        <h1 class="title mb-3">{{ $t('promotion.dynamicDepositBonus.bannerTitle', { amount: bonusAmount }) }}</h1>
        <div class="desc" v-html="$t('promotion.dynamicDepositBonus.bannerDesc')"></div>
      </div>
    </div>
    <!--Data Configuration Part-->
    <div class="content-box">
      <div class="inner">
        <FundAccount
          :optIn="optIn"
          :dataConfiguration="dataConfiguration"
          :showClearCreditPopup="showClearCreditPopup"
          @successfulCallback="successfulCallback"
        ></FundAccount>
        <div class="over-bonus-alert" v-if="overBonus">
          {{ $t(`promotion.depositBonus.overBonus`, { totalLimit: totalLimit }) }}
        </div>
        <div class="table-box">
          <div class="table-title">
            {{ $t(`promotion.depositBonus.bonusHistory`) }}
          </div>
          <ul class="table-demo">
            <li class="table-item" :class="{ active: activeName === 'creditReceived' }">
              <CreditReceived :tableData="tableData" :welcomeRateLimit="welcomeRateLimit"></CreditReceived>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FundAccount from '@/components/promotion/newDepositBonus/FundAccount';
import CreditReceived from '@/components/promotion/newDepositBonus/CreditReceived';
import { apiCampaignInfo } from '@/resource';

export default {
  name: 'DynamicDepositBonus',
  components: { FundAccount, CreditReceived },
  data() {
    return {
      activeName: 'creditReceived',
      dataConfiguration: {},
      overBonus: true,
      tableData: [],
      optIn: false,
      totalLimit: 10000,
      welcomeRateLimit: 0,
      campaigns: {
        svg: [6, 32, 43, 5007, 5009, 5010, 5011, 5015, 5017, 5018],
        fsa: [6, 32, 43, 5003, 5004, 5005, 5006, 5010, 5011, 5012]
      },
      showClearCreditPopup: false
    };
  },
  computed: {
    campaignID() {
      return this.$route.params.campaignId ? Number(this.$route.params.campaignId) : null;
    },
    langUsed() {
      return this.$store.state.common.lang;
    },
    isMena() {
      return (
        (this.regulator === 'svg' && this.campaignID === 5014) || (this.regulator === 'fsa' && this.campaignID === 5009)
      );
    },
    isOthers() {
      return (
        (this.regulator === 'svg' && this.campaigns.svg.includes(this.campaignID)) ||
        (this.regulator === 'fsa' && this.campaigns.fsa.includes(this.campaignID))
      );
    },
    bonusAmount() {
      const isPtDe = this.langUsed === 'pt' || this.langUsed === 'de';
      if (this.isOthers) {
        return isPtDe ? '10.000' : '10,000';
      }
      return this.isMena ? (isPtDe ? '30.000' : '30,000') : isPtDe ? '10.000' : '10,000';
    }
  },
  watch: {
    langUsed() {
      this.getDespositBonusInfo();
    },
    campaignID() {
      this.getDespositBonusInfo();
    }
  },
  methods: {
    getDespositBonusInfo() {
      if (this.campaignID == null) return;
      apiCampaignInfo(this.campaignID, this.langUsed)
        .then(resp => {
          if (resp.data.code === 0) {
            this.tableData = resp.data.data.participants;
            this.optIn = resp.data.data.in;
            this.overBonus = resp.data.data.reachedMaximumBonus;
            this.totalLimit = resp.data.data.creditLimit;
            this.welcomeRateLimit = resp.data.data.welcomeRateLimit;
            this.dataConfiguration = resp.data.data.clientPortalSettings;
            this.showClearCreditPopup = resp.data.data?.showClearCreditPopup
              ? resp.data.data.showClearCreditPopup
              : false;

            this.showClearCreditPopup = true;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    successfulCallback() {
      this.getDespositBonusInfo();
    }
  },
  mounted() {
    this.getDespositBonusInfo();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/depositBonus.scss';
</style>
