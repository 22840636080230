<template>
  <div class="div_dialog">
    <ConditionDialog :visible.sync="open" :isSuccess="successCallbackType" @closCbDialog="handleClose">
      <template #content>
        <i18n
          :path="
            successCallbackType
              ? `promotion.dynamicDepositBonus.optInDialog`
              : `promotion.dynamicDepositBonus.optOutDialog`
          "
          tag="p"
        >
          <template v-slot:br>
            <br />
          </template>
        </i18n>
        <div class="btn-box">
          <slot name="btn"></slot>
        </div>
      </template>
    </ConditionDialog>
  </div>
</template>

<script>
import ConditionDialog from '@/components/dialog/ConditionDialog.vue';

export default {
  name: 'callbackDialog',
  props: {
    show: Boolean,
    successCallbackType: Boolean
  },
  components: {
    ConditionDialog
  },
  data() {
    return {
      open: false
    };
  },
  watch: {
    show(val) {
      this.open = val;
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:show', false);
    }
  }
};
</script>
