<template>
  <div class="meta-box">
    <h3>{{dataConfiguration.pageTitle || dataConfiguration.title}}</h3>
    <el-row :gutter="20" class="sub-box1">
      <el-col :xs="24">
        <div
          class="descp3"
          v-html="
            optIn
              ? $t(`promotion.dynamicDepositBonus.optOutDescription`)
              : $t(`promotion.dynamicDepositBonus.optInDescription`)
          "
        ></div>
      </el-col>
      <el-col :xs="24" class="button-box opt-button-box">
        <el-button
          class="el-button btn-blue long-button"
          data-testid="opt"
          @click="preDespositBonusOpt"
          v-html="optIn ? $t(`promotion.depositBonus.signOut`) : $t(`promotion.depositBonus.activate`)"
        >
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="sub-box2">
      <el-col :xs="24">
        <div class="descp2 vhtml_component" v-html="dataConfiguration.description1"></div>
      </el-col>
      <el-col :xs="24">
        <div class="descp2 vhtml_component" v-html="dataConfiguration.description2"></div>
      </el-col>
      <el-col :xs="24" class="button-box">
        <el-button class="el-button btn-blue long-button" data-testid="fund" @click="goToFund" :disabled="!optIn">
          {{ $t(`promotion.depositBonus.fund`) }}</el-button
        >
      </el-col>
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.depositBonus.tnc">
          <template v-slot:link>
            <a :href="dataConfiguration.tncLink" target="_blank" data-testid="tnc">
              {{ $t(`promotion.depositBonus.link`) }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>
    <!--not changing, cause no changes-->
    <AgreeTnc :agreeTncVisible.sync="agreeTncVisible" @isAgreeTnc="isAgreeTncHandler">
      <template v-slot:desc>
        <div class="agreeTnc-box">
          <i18n path="promotion.depositBonus.tnc">
            <template v-slot:link>
              <a :href="dataConfiguration.tncLink" target="_blank" data-testid="tnc">
                {{ $t(`promotion.depositBonus.link`) }}
              </a>
            </template>
          </i18n>
        </div>
      </template>
    </AgreeTnc>

    <ClearCreditDialog :clearCreditVisible.sync="clearCreditDialogVisible" @confirmOptOut="postDespositBonusOpt">
    </ClearCreditDialog>

    <CallbackDialog :show.sync="successCallback" :successCallbackType="successCallbackType" />
  </div>
</template>

<script>
import { apiCampaignOpt } from '@/resource';
import AgreeTnc from '@/components/promotion/common/AgreeTnc';
import ClearCreditDialog from '@/components/promotion/common/ClearCreditDialog';
import CallbackDialog from '@/components/promotion/newDepositBonus/CallbackDialog';
import blackList from '@/mixins/blackList';

export default {
  name: 'FundAccount',
  components: {
    AgreeTnc,
    ClearCreditDialog,
    CallbackDialog
  },
  props: {
    optIn: Boolean,
    dataConfiguration: Object,
    showClearCreditPopup: {
      type: Boolean,
      default: false
    }
  },
  mixins: [blackList],
  data() {
    return {
      form: {
        checked: true
      },
      successCallback: false,
      successCallbackType: null,
      agreeTncVisible: false,
      clearCreditDialogVisible: false,
      isAgreeTnc: false
    };
  },
  watch: {
    optIn: {
      immediate: true,
      handler(val) {
        this.form.checked = val;
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    goToFund() {
      this.getDepositBlacklist();
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    isAgreeTncHandler(val) {
      if (val) {
        this.postDespositBonusOpt();
      }
    },
    preDespositBonusOpt() {
      this.agreeTncVisible = this.form.checked
        ? this.showClearCreditPopup
          ? this.clearCreditWarning()
          : this.postDespositBonusOpt()
        : true;
    },
    clearCreditWarning() {
      this.clearCreditDialogVisible = true;
    },
    postDespositBonusOpt() {
      if (!this.$route.params.campaignId) {
        return;
      }
      this.form.checked = !this.form.checked;
      apiCampaignOpt(this.$route.params.campaignId, this.form.checked)
        .then(resp => {
          if (resp.data.data) {
            this.successCallback = true;
            this.successCallbackType = this.form.checked;
            this.$emit('successfulCallback');
          } else {
            this.showError();
            this.form.checked = this.optIn;
          }
        })
        .catch(err => {
          this.showError();
          this.form.checked = this.optIn;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/clientPortalSettings.scss';
</style>
