<template>
  <div class="promotion">
    <DialogVersion3 :visible.sync="visible" :visibleFooter="true" :hideChat="true">
      <div class="top">
        <!-- <div class="header-text">{{ $t('promotion.header') }}</div> -->
        <div class="header-text">{{ $t('promotion.clearCreditDialog.title') }}</div>
        <div class="mb-2">{{ $t('common.field.notes') }}:</div>
        <div class="mb-2" v-html="$t('promotion.clearCreditDialog.desc')"></div>
      </div>

      <div class="form-box">
        <div class="btn-box">
          <el-button
            class="btn-default"
            @click="disableClearCreditVisible(false)"
            :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
            data-testid="cancelOptOut"
          >
            {{ $t('common.button.cancel') }}
          </el-button>

          <el-button
            class="btn-blue"
            @click="agreeAction"
            :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
            data-testid="clearCreditUponOptOut"
          >
            {{ $t('common.button.confirm') }}
          </el-button>
        </div>
      </div>
    </DialogVersion3>
  </div>
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { checkAgreedTNC } from '@/util/validation';

export default {
  props: {
    clearCreditVisible: Boolean,
    tncContent: String
  },
  components: { DialogVersion3 },
  data() {
    return {
      visible: false,
      form: {
        checked: false
      },
      rule: {
        checked: [{ validator: checkAgreedTNC, trigger: 'change' }]
      }
    };
  },
  watch: {
    clearCreditVisible(bool) {
      this.form.checked = false;
      this.visible = bool;
    },
    visible(bool) {
      this.disableClearCreditVisible(bool);
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    disableClearCreditVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:clearCreditVisible', bool);
      });
    },
    agreeAction() {
      this.$emit('confirmOptOut');
      this.disableClearCreditVisible(false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/promotion.scss';
</style>
